import {faClock, faEnvelope, faLocationDot, faPhone,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ChangeEvent, FormEvent, useState} from "react";

export const ContactPage = () => {
  const { REACT_APP_STRAPI_URI } = process.env;

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const sendEmail = async () => {
    const formData: FormData = new FormData();
    formData.append("to", "info@swedec.se");
    formData.append("subject", `New email from ${email}`);
    formData.append(
      "text",
      `
          You received an email from ${name}, ${email}${phoneNumber ? ', Phone number: '  + phoneNumber : ''}. \n 
          Message: \n 
          ${message} \n\n 
      `
    );

    const requestOptions: RequestInit = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    fetch(`${REACT_APP_STRAPI_URI}/api/email`, requestOptions)
      .then((response) => response.ok)
      .then((response) => {
        setEmail("");
        setName("");
        setMessage("");
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="flex flex-col md:flex-row center">
        <div className="w-full md:w-1/2 bg-blue-swedec">
          <h3 className="text-2xl sm:text-4xl text-white font-bold ml-10 my-5">
            Mejla Oss
          </h3>
          <form
            className="p-10"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              sendEmail();
            }}
          >
            <input
              className="w-full p-3 mb-3"
              placeholder="Namn"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
            <input
              className="w-full p-3 mb-3"
              placeholder="Email adress"
              type="email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <input
                className="w-full p-3 mb-3"
                placeholder="Telefon nummer"
                type="tel"
                value={phoneNumber}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPhoneNumber(e.target.value)
                }
            />
            <textarea
              className="w-full p-3 mb-3 h-32"
              placeholder="Meddelande"
              value={message}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setMessage(e.target.value)
              }
            />
            <button className="w-full bg-darkblue-swedec text-white font-bold p-3">
              Skicka Meddelande
            </button>
          </form>
        </div>
        <div className="w-full md:w-1/2 flex flex-col text-darkblue-swedec justify-center items-center">
          <div className="flex bg-gray-50 rounded-md p-6 w-full md:w-96 text-base m-2">
            <FontAwesomeIcon className="text-2xl" icon={faPhone} />
            <p className="pl-10">+46 302 236 50</p>
          </div>

          <div className="flex bg-gray-50 rounded-md p-6 w-full md:w-96 text-base m-2 items-center">
            <FontAwesomeIcon className="text-2xl" icon={faLocationDot} />
            <div>
              <p className="pl-10">Hedeforsvägen 2</p>
              <p className="pl-10">44361 Stenkullen </p>
              <p className="pl-10">Sverige</p>
            </div>
          </div>

          <div className="flex bg-gray-50 rounded-md p-6 w-full md:w-96 text-base m-2">
            <FontAwesomeIcon className="text-2xl" icon={faEnvelope} />
            <p className="pl-10">info@swedec.se</p>
          </div>

          <div className="flex bg-gray-50 rounded-md p-6 w-full md:w-96 text-base m-2 items-center">
            <FontAwesomeIcon className="text-2xl" icon={faClock} />
            <div>
              <p className="pl-10">Mån - Fre: 07.30 - 16.00</p>
              <p className="pl-10">Lunch: 12.00 - 12.45</p>
              <p className="pl-10"> Lör-Sön: Stängt</p>
            </div>
          </div>

          <div className="flex bg-gray-50 w-full text-base  items-center">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2126.98749674067!2d12.303483777381068!3d57.78458983369756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464557d1f048e00f%3A0x72c93d49e48a561a!2sHedeforsv%C3%A4gen%202%2C%20443%2061%20Stenkullen%2C%20Sweden!5e0!3m2!1sen!2suk!4v1692451742625!5m2!1sen!2suk"
              width="100%"
              height="450"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
